import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import {IndexPageTemplate} from "./index-page";

const IndexPageEn = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark
  return (
      <Layout location={location}>
          <IndexPageTemplate
            title={frontmatter.title_en}
            heading={frontmatter.heading_en}
            subheading={frontmatter.subheading_en}
            intro={frontmatter.intro_en}
            youtube={frontmatter.youtube}
            advantages={frontmatter.advantages}
            en={new RegExp("en").test(location.pathname)}
            location={location}
          />
        </Layout>
  )
}

IndexPageEn.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPageEn

export const pageQueryEn = graphql`
  query IndexPageTemplateDe {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title_en
        subheading_en
        intro_en
        youtube
        advantages {
            advantage
            advantage_en
        }
      }
    }
  }
`
